import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import HeroBanner from "../components/HeroBanner"
import PortfolioItem from "../components/PortfolioItem"
import Container from "../components/Container"
import CTA from "../components/CTA"
import SEO from "../components/SEO"

export default function Portfolio({ data }) {
  const { allMarkdownRemark } = data // data.markdownRemark holds your post data

  return (
    <div>
      <SEO title="Portfolio" />
      <HeroBanner
        title="Portfolio"
        subTitle="Here are a few projects I've worked on recently. "
      />
      <div className="has-background-white-ter py-6">
        <Container>
          <div className="columns is-multiline">
            {allMarkdownRemark.edges.length > 0
              ? allMarkdownRemark.edges.map(portfolioItem => (
                  <PortfolioItem
                    data={portfolioItem.node}
                    key={get(portfolioItem, "node.frontmatter.slug")}
                  />
                ))
              : null}
          </div>
        </Container>
      </div>
      <CTA works={false} />
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___order] }) {
      edges {
        node {
          html
          frontmatter {
            title
            slug
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 828) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
