import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import get from "lodash.get"

const PortfolioItem = ({ data }) => {
  const slug = get(data, "frontmatter.slug", "/")
  const image = get(data, "frontmatter.thumbnail.childImageSharp.fluid", null)

  return (
    <div className="column is-half">
      <article className="card">
        {image ? (
          <div className="card-image">
            <figure className="image is-4by3">
              <Link to={slug}>
                <Img fluid={image} />
              </Link>
            </figure>
          </div>
        ) : null}

        <div className="card-content">
          <div className="content">
            <Link to={slug}>{data.frontmatter.title}</Link>
          </div>
        </div>
      </article>
    </div>
  )
}

PortfolioItem.propTypes = {
  data: PropTypes.shape({
    frontmatter: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.object,
    }),
    html: PropTypes.string.isRequired,
  }),
}

export default PortfolioItem
